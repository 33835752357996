import React, { useState, useEffect, useRef } from 'react';
import { formatDate, sortedAndFilteredDrills, handleSortDrill } from "@data/helpers";
import { Autocomplete, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Popup from "@components/Popup";
import Metronome from "@pages/Admin/Home/Practice";
import { useSelector } from 'react-redux';
import { getUser } from '@data/redux/usersSlice';

const DrillTable = ({ drills, setPopupAddDrill, setPopupDeleteDrill, role="admin" }) => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedInstrument, setSelectedInstrument] = useState('All');
  const [drillPicOverlay, setDrillPicOverlay] = useState(false);
  const [hoveredDrill, setHoveredDrill] = useState(null);
  const [sortConfigDrill, setSortConfigDrill] = useState({ key: 'date', direction: 'desc' });
  const [searchDrill, setSearchDrill] = useState('');
  const [categories, setCategories] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [popupMetronome, setPopupMetronome] = useState(false);
  const [popupBpm, setPopupBpm] = useState(100);
  const [popupName, setPopupName] = useState('');
  const user_store = useSelector(getUser);

  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('All');

  const getSortClass = (key) => {
    return sortConfigDrill.key === key ? `sort ${sortConfigDrill.direction}` : '';
  };

  useEffect(() => {
    console.log('xx', user_store);
    const uniqueCategories = [...new Set(drills.map(drill => drill.category).filter(Boolean))];
    const cat_list = uniqueCategories.map(category => ({
      id: uuidv4(), label: category
    }));
    setCategories(cat_list);
    const uniqueInstruments = [...new Set(drills.map(drill => drill.instrument).filter(Boolean))];
    const instr_list = uniqueInstruments.map(instrument => ({
      id: uuidv4(), label: instrument
    }));
    setInstruments(instr_list);
    const uniqueTypes = [...new Set(drills.map(drill => drill.type).filter(Boolean))];
    const types_list = uniqueTypes.map(type => ({
      id: uuidv4(), label: type
    }));
    setTypes(types_list);
  }, [drills]);

  const handleSort = (key) => {
    handleSortDrill(key, sortConfigDrill, setSortConfigDrill);
  };

  const filteredDrills = drills.filter(drill => 
    (selectedCategory === 'All' || drill.category === selectedCategory) &&
    (selectedInstrument === 'All' || drill.instrument === selectedInstrument) &&
    (selectedType === 'All' || drill.type === selectedType)
  );

  const handlePracticeWithSettings = (drillName, bpm) => {
    setPopupMetronome(true); setPopupBpm(bpm); setPopupName(drillName);
  };

  return (
    <div className="users-content">
      <Popup
        title="Metronome" active={popupMetronome} cancel={false} savePopup={false}
        closePopup={() => { setPopupMetronome(false); }}
      >
        <Metronome startname={popupName} startbpm={popupBpm} />
      </Popup>
      <div className="search-add">
        <input type="text" placeholder="Search" value={searchDrill} onChange={(event) => setSearchDrill(event.target.value)} />
        <FormControl style={{ minWidth: 150, marginLeft: 10 }}>
          <InputLabel>Category</InputLabel>
          <Select
            value={selectedCategory}
            onChange={(event) => setSelectedCategory(event.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            {categories.map(category => (
              <MenuItem key={category.id} value={category.label}>{category.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 150, marginLeft: 10 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={selectedType}
            onChange={(event) => setSelectedType(event.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            {types.map(type => (
              <MenuItem key={type.id} value={type.label}>{type.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 150, marginLeft: 10 }}>
          <InputLabel>Instrument</InputLabel>
          <Select
            value={selectedInstrument}
            onChange={(event) => setSelectedInstrument(event.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            {instruments.map(instrument => (
              <MenuItem key={instrument.id} value={instrument.label}>{instrument.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        { ((role === 'admin' && user_store.role === 'SUPERADMIN') || (role === 'user')) && (
          <div className="btn-main small" onClick={() => setPopupAddDrill(true)}>Add New Drill</div>
        )}
      </div>

      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('date')} className={getSortClass('date')}>Date</th>
            <th onClick={() => handleSort('drill')} className={getSortClass('drill')}>Drill Name</th>
            <th onClick={() => handleSort('bpm')} className={getSortClass('bpm')}>BPM</th>
            <th onClick={() => handleSort('link')} className={getSortClass('link')}>Link</th>
            <th onClick={() => handleSort('category')} className={getSortClass('category')}>Category</th>
            <th onClick={() => handleSort('type')} className={getSortClass('type')}>Type</th>
            <th onClick={() => handleSort('instrument')} className={getSortClass('instrument')}>Instrument</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedAndFilteredDrills(filteredDrills, searchDrill, sortConfigDrill).map(drill => (
            <tr key={drill.id}>
              <td>{formatDate(drill.date)}</td>
              <td 
                onMouseEnter={() => setHoveredDrill(drill.file)} 
                onMouseLeave={() => setHoveredDrill(null)}
                style={{ position: 'relative' }} // Add relative positioning to the cell
              >
                {drill.drill}
                { (hoveredDrill === drill.file) && (
                  <>
                  {(drill.file) && (
                    <div className="tooltip">
                      <img onClick={ () => setDrillPicOverlay(drill.file) } src={drill.file} alt="Drill Preview" className="tooltip-image" />
                    </div>
                  )}
                  </>
                )}
              </td>
              <td>{drill.bpm}</td>
              <td>{drill.link ? <a href={drill.link} target="_blank" rel="noopener noreferrer">View</a> : ''}</td>
              <td>{drill.category || 'N/A'}</td>
              <td>{drill.type || 'metronome'}</td>
              <td>{drill.instrument || ''}</td>
              <td>
                <button
                  onClick={() => handlePracticeWithSettings(drill.drill, drill.bpm)}
                  className="btn-action"
                >
                  Load Drill
                </button>
                { ((role === 'admin' && user_store.role === 'SUPERADMIN') || (role === 'user')) && (
                  <>
                    <div className="btn-edit" onClick={() => setPopupAddDrill(drill)}>Edit</div> 
                    <div className="btn-delete" onClick={() => setPopupDeleteDrill(drill)}>Delete</div>
                  </>
                )}
                
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DrillTable;
